const initialState = {
  userInfo: 'empty Auth',
  sidebarShow: 'responsive',
  license: 'empty License',
  license_name: 'empty License',
  license_type : 'empty type',
  userName: 'empty Name',
  businessInfo: 'empty Business',
  businessNo: 'empty Business',
  accountId : 'guest',
  influxID : 'empty Sensor',
  lot_num : 0,
  refreshLicense : false,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case 'license':
      return {...state, ...rest }
    case 'license_name':
      return {...state, ...rest }
    case 'license_type':
      return {...state, ...rest }
    case 'userInfo':
      return {...state, ...rest }
    case 'userName':
      return {...state, ...rest }
    case 'businessInfo':
      return {...state, ...rest }
    case 'businessNo':
      return {...state, ...rest }
    case 'accountId':
      return {...state, ...rest }
    case 'influxID':
      return {...state, ...rest }
    case 'refreshLicense':
      return {...state, ...rest }
    case 'lot_num':
      return {...state, ...rest }
    case 'selectedLotNum':
      return {...state, ...rest }
    default:
      return state
  }
}

export default changeState