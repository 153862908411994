import changeState from './store';
//import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage : storageSession
  };
  
const rootReducer = persistReducer(persistConfig, changeState) ;

export default rootReducer;